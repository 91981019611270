<template>
  <div class="query-container">
    <header-bar>
      <img
        src="~@/assets/img/切图_web_0628/logo-02.png"
        width="49px"
        height="49px"
      />
      <span class="header-title">艾佳德</span>
    </header-bar>
    <nav-bar />
    <Banner
      :backgroundImage="bannerConfig.backgroundImage"
      :iconImg="bannerConfig.iconImg"
      :content="bannerConfig.content"
      :title="bannerConfig.title"
      :desc="bannerConfig.desc"
    />

    <div class="search-wrapper">
      <div class="bm-content-container">
        <div class="search">
          <el-input v-model="query" placeholder="请输入内容"></el-input>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </div>

        <div class="content">
          <div class="item" v-for="item in queryList" :key="item.id">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "./components/header.vue";
import NavBar from "./components/nav.vue";
import Banner from "./components/banner.vue";
import backgroundImage from "@/assets/img/bg_授权机构.png";

import axios from "axios";

export default {
  components: {
    HeaderBar,
    NavBar,
    Banner,
  },
  data() {
    return {
      query: "",
      queryList: [],
      bannerConfig: {
        backgroundImage: backgroundImage,
        content: "心理咨询师专业技能培训项目",
        title: "联合机构",
        desc: {
          two: "JOINT  AGENCY",
        },
      },
    };
  },
  methods: {
    handleSearch() {
      if (this.query !== "") {
        axios
          .get("https://rest-zgxlwsxh.oxcoder.com/api/company/selectCompany", {
            params: {
              name: this.query,
            },
          })
          .then((response) => {
            const res = response.data;
            if (res.code === 200) {
              this.queryList = res.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }else {
        this.queryList = []
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.query-container {
  .header-title {
    font-size: 34px;
    color: #fff;
    font-weight: 500;
    margin-left: 5px;
  }

  .search-wrapper {
    .bm-content-container {
      .search {
        display: flex;
        justify-content: space-between;
        .el-input {
          width: 90%;
        }
      }
      .content {
        .item {
          display: inline-block;
          width: 300px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background: #f5f5f5;
          border-radius: 8px;
          margin-top: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
